$(document).ready(function () {

	$(document).foundation();

	FoundationModal.Init();

	// Fontsize.Init();

	// Contrast.Init();

	// WCAG.Fix();

	Surveys.Init();

	checkboxStyled();

	anotherOption();

	// Menu.init();

	Login.Init();

	// WCAG_menu.init();

    Site.Init();

	$(window).scroll(function (event) {
		var scroll = $(window).scrollTop();
		var screen = scroll + $(window).height();

		Menu.fixed(scroll);
	});


	//=============================================//
	//--------------- LIGHTBOX INIT ---------------//
	//=============================================//
	var selectorF = "a[rel*=lightbox]";
	var instanceF = $(selectorF).imagelightbox(
	{
		onStart: function () { overlayOn(); closeButtonOn(instanceF); arrowsOn(instanceF, selectorF); },
		onEnd: function () { overlayOff(); captionOff(); closeButtonOff(); arrowsOff(); activityIndicatorOff(); },
		onLoadStart: function () { captionOff(); activityIndicatorOn(); },
		onLoadEnd: function () { captionOn(); activityIndicatorOff(); $('.imagelightbox-arrow').css('display', 'block'); }
	});

	//=============================================//


    


});
