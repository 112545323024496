var Site = {
    plugins : {
        'pickadate':'[data-pickadate]',
        'chosen': {
            'selector':'[data-chosen]',
            'config': {
                'disable_search_threshold':10
            }
        },
        'dotdotdot': {
            'selector': '[data-truncate]',
            'config': {
                'watch': true
            }
        },
        'fullpage': {
            'selector':'html:not(.admin-preview) body #fullpage',
            'config':{
                'sectionSelector' : '[data-fullpage]',
                'responsiveWidth' : 1200,
                'responsiveHeight' : 625,
                // 'anchors' : ['witamy', 'hotel', 'restauracja', 'akademia-konska', 'nasze-akademie', 'subskrypcja', 'kontakt', 'realizacja'],
                'loopBottom' : false,
                'loopTop' : false,
                'continuousVertical': false,
                'scrollBar' : true,
                'fixedElements' : '.top-nav.small, #scroll-helper',
                'normalScrollElements' : '[data-modal]',
                afterLoad : function(a,b) {
                    $helper = $('#scroll-helper');
                    if(b > 5 && $helper.is(":visible"))
                    {
                        $helper.fadeOut();
                    }
                    else if(b <= 5 && !$helper.is(":visible"))
                    {
                        $helper.fadeIn();
                    }
                }
            },
            'callback' : function(){
                $('.owl-carousel').trigger('refresh.owl.carousel');
            }
        },
    },
    Init: function()
    {
        this.initPlugins();
        this.mobileReservations();
        this.mobileMenu();
        this.ripple();
        this.pagelist();
        this.rwdTable();
        this.bannerHandler();
        this.CircleBanners();
        this.scrollToAnchor();
    },
    CircleBanners: function()
    {
        $('.circle-banner').each(function(){
            
            var __active = false,
                $items = $('[data-circle]', this),
                $buttons = $('[data-circle-toggle]', this),
                $close = $('[data-circle-close]', this);
            
            
            $buttons.on("click", function(){
                var target = $(this).data('circle-toggle');
                if( target !== "" ){
                    $items.filter('.i-'+target).trigger('circle:show');
                }
            });
            
            $close.on("click", function(){
                $items.trigger('circle:hide');
            });
            
            $items.on("circle:show", function(el){
                $this = $(this);
                $items.trigger('circle:hide');
                $this.addClass('active');
                __active = $this;
            });
            
            $items.on("circle:hide", function(){
                if(__active) {
                    __active.removeClass('active');
                }
            });
        });
        
        $(document).ready(function(){
            setTimeout(function(){
                $(".circle-banner .button.show").eq(0).trigger('click');
            }, 3000);
        });
    },
    bannerHandler: function()
    {
        setTimeout(function(){
            $('[data-modal]').addClass('active');
        }, 1500);

        $('.closeWindow', '[data-modal]').on("click", function(){
            $(this).parents('[data-modal]').removeClass('active');
        });
    },
    initPlugins: function()
    {
        for(var function_name in this.plugins)
        {
            if(typeof $.fn[function_name] === "function")
            {
                if(typeof this.plugins[function_name] === "object")
                {
                    $(this.plugins[function_name].selector)[function_name](this.plugins[function_name].config);
                }
                else if(typeof this.plugins[function_name] === "string")
                {
                    $(this.plugins[function_name])[function_name]();
                }
            }
            if(typeof this.plugins[function_name].callback === "function")
            {
                this.plugins[function_name].callback();
            }
        }
    },
    mobileReservations: function()
    {
        $('[data-reservations] [data-toggle]').on('click', function(){
            var $popup = $('[data-reservations] [data-popup]');
            if($popup.hasClass('visible'))
            {
                $popup.removeClass('visible');
            }
            else
            {
                $('[data-popup]').removeClass('visible');
                $popup.addClass('visible');
            }
        });
    },
    mobileMenu: function()
    {
        var $body = $('body'),
            $toggler = $('[data-menu] [data-toggle]'),
            $menu = $('[data-menu] [data-popup]'),
            $menu_el = $menu.find('.main-menu li'),
            MENU_STATE = false,
            MENU_BUSY = false,
            MENU_DELAY = 200,
            MENU_DURATION = 360;

        var screenHeight = document.documentElement.clientHeight

        $(window).on('resize', function(){
            var height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            $menu.css({'height' : height});
        }).trigger('resize');

        $toggler.on('click', function(){

            if(MENU_STATE === true && MENU_BUSY !== true)
            {
                MENU_BUSY = true;
                $toggler.removeClass('toggled');
                $menu_el.css('top', 0).each(function(i, el){
                    $(this).delay(i*MENU_DELAY).animate(
                        {
                            top : -20,
                            opacity : 0
                        },
                        {
                            step : function(now, fx) {
                                if(fx.prop == 'top')
                                {
                                    $(this).css('transform', 'translateX('+now+'%)');
                                }
                            },
                            duration: MENU_DURATION
                        }
                    );
                }).promise().done(function(){
                        $menu.removeClass('visible');
                        $body.css('overflow', 'visible');
                        MENU_STATE = false;
                        MENU_BUSY = false;
                });
            }
            else if(MENU_STATE === false && MENU_BUSY !== true)
            {
                MENU_BUSY = true;
                $body.css('overflow', 'hidden');
                $toggler.addClass('toggled');
                $menu.addClass('visible');
                $menu_el.css('top', -20).each(function(i, el){
                    $(this).delay(i*MENU_DELAY).animate(
                        {
                            top : 0,
                            opacity : 1
                        },
                        {
                            step : function(now, fx) {
                                if(fx.prop == 'top')
                                {
                                    $(this).css('transform', 'translateX('+now+'%)');
                                }
                            },
                            duration: MENU_DURATION
                        }
                    );
                }).promise().done(function(){
                        MENU_STATE = true;
                        MENU_BUSY = false;
                });
            }
        });
    },
    ripple: function()
    {
        if(typeof $.ripple === "function")
        {
            $.ripple(
                "button:not(.noripp), input[type=\"button\"]:not(.noripp), a.ripp",
                {
                    'multi':true,
                    'color':'#fff'
                }
            );
            $.ripple(
                ".ripp-dark",
                {
                    'multi':true,
                    'color':'auto'
                }
            );
        }
    },
    pagelist: function()
    {
        $(window).on('load resize', function(){
            var $widgets = $('.subpages-widget');

            if($widgets.length > 0)
            {
                var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
                if(width < 640)
                {
                    $widgets.each(function(i, el){
                        var $this = $(this),
                            $this_el = $this.find('.item, [data-toggle].toggle-bottom'),
                            this_state = $this.hasClass('expanded') ? true : false;

                        $('[data-toggle]', $this).on("click", function(){
                            $toggler = $this.find('[data-toggle]');

                            if(this_state === true)
                            {
                                $this.removeClass('expanded');
                                this_state = false;
                            }
                            else if(this_state === false)
                            {
                                $this.addClass('expanded');
                                this_state = true;
                            }
                        });
                    });
                }
            }
        });
    },
    scrollToAnchor: function()
    {
        $('a[href*=#]', '.header-nav').on("click", function(e) {
            e.preventDefault();
            var hash = $.attr(this, 'href');
            if($(hash).length > 0)
            {
                $('html, body').animate({
                    scrollTop: $(hash).offset().top
                }, 800);
                return false;
            }
        });
        
        
    },
    rwdTable: function()
    {
        $('#content table').wrap('<div class="rwd-table" tabindex="0">');
    }
};