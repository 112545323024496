var WCAG_menu = {
	init: function()
	{
		$('#main-menu a.level-one').focus(function(){
			$('#main-menu').find('ul.open').removeClass('open');
		});
		$('#main-menu a.level-one').hover(function(){
			$('#main-menu').find('ul.open').removeClass('open');
		});
	},
	focusOnClick: function($obj)
	{
		$obj.closest('li').find('ul').addClass('open').find('a').first().focus();
	}
}
