Menu = {
	$topBar : 'nav.top-bar',
	$mainMenu: '#main-menu',
	mainMenuTop : 0,
	init: function()
	{
		if(!tablet)
		{
			this.mainMenuTop = $(this.$topBar).position().top;
		}
	},
	fixed: function(scroll)
	{
		if(scroll>=this.mainMenuTop && !tablet)
		{
			$(this.$topBar).addClass('fixedMenu');
			$(this.$topBar).closest('.row').addClass('fixedMenu');
		}
		else if(!tablet)
		{
			$(this.$topBar).removeClass('fixedMenu');
			$(this.$topBar).closest('.row').removeClass('fixedMenu');
		}
	},
	open: function($handle)
	{
		$closest = $handle.attr('data-closest');
		$handle.toggleClass('active').closest($closest).children('ul').slideToggle();
	}
};