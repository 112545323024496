var Fontsize = {
	parent: 'html',
	className: 'fontsize-',
	classActive: 'active',
	elements: '.fontsize a',
	size: 1,

	Init: function () {
		var cookie = this.GetCookie();

		if (!isNaN(cookie)) {
			this.size = cookie;
			this.Change();
		}

		this.onClick();
	},

	Change: function () {
		if (isNaN(this.size)) {
			return;
		}

		Fontsize.RemoveClass();
		Fontsize.AddClass();
		Fontsize.SetCookie();

		Fontsize.ClearActive();
		Fontsize.SetActive();
	},

	onClick: function () {
		$(this.elements).on('click', function () {
			Fontsize.GetSize(this);
			Fontsize.Change();
		});
	},

	GetSize: function (element) {
		this.size = parseInt($(element).data('size'), 10);
	},

	AddClass: function () {
		var name = this.className + this.size;
		$(this.parent).addClass(name);
	},

	RemoveClass: function () {
		$(this.parent).removeClass(this.className + '1');
		$(this.parent).removeClass(this.className + '2');
		$(this.parent).removeClass(this.className + '3');
	},

	SetCookie: function () {
		$.cookie('fontsize', this.size, {path: '/'});
	},

	GetCookie: function () {
		return parseInt($.cookie('fontsize'), 10);
	},

	SetActive: function () {
		var i = this.size - 1,
			_element = $(this.elements)[i],
			element = $(_element);

		element.addClass(this.classActive);
	},

	ClearActive: function () {
		$(this.elements).removeClass(this.classActive);
	}
};